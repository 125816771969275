@use '../variables' as var;
@use '../mixins' as mix;

.impressum {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 0;

    &__container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}