@use './mixins' as mix;

// Color variables
$red-blue-background-gradient-icon: radial-gradient(circle, rgba(158,35,156,0.9598214285714286) 0%, rgba(54,102,208,0.9710259103641457) 100%);
$red-blue-background-gradient: radial-gradient(circle, rgba(242, 12, 208, 0.853) 0%, rgba(20, 152, 222, 0.971) 100%);

$clr-primary: rgba(136, 28, 157, 1);
$clr-primary-light: rgba(136, 28, 157, 0.5);
$clr-primary-dark: #091034;
$clr-gray100: #f9fbff;
$clr-gray150: #f4f6fb;
$clr-gray200: #eef1f6;
$clr-gray300: #e1e5ee;
$clr-gray400: #767b91;
$clr-gray500: #4f546c;
$clr-gray600: #2a324b;
$clr-gray700: #161d34;
$clr-bg: #060b23;

// Border Radius
$border-sm: 5px;
$border-med: 10px;
$border-round: 50%;

// Header Height
$header-height: 5.438rem;

// Fonts
$title-font: 'Bumbon', sans-serif;
$content-font: 'Poppins', sans-serif;

// Animations
.hover-underline-animation {
  display: inline-block;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.9);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  @media (hover: hover) and (pointer: fine) {
    cursor: pointer;
    &:hover:after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }
}

.buttonFill {
  border: 1px solid rgba(86, 82, 82, 1);
  cursor: pointer;
  border-radius: $border-med;
  background: $red-blue-background-gradient;
  width: 10rem;
  min-width: fit-content;
  color: $clr-gray100;
  padding: 1rem 2rem;
  box-shadow: rgba(0, 0, 0, 0.41) 0px 3px 8px;
  font-weight: bold;
  text-transform: uppercase;
  
  @include mix.lg {
    background: transparent;
}    
  @media (hover: hover) and (pointer: fine) {
      cursor: pointer;
      transition: all 400ms ease-in-out;
      background: linear-gradient(to left, rgba(224, 224, 231, 0.078) 50%, rgba(165, 33, 191, 0.88) 50%) right;
      background-size: 200%;
      color: $clr-bg;
      
      &:hover {
          background-position: left;
          color: $clr-gray100;
      }
  }
}

// Padding 
.container {
  padding: 0 1rem;
  width: 100%;

  @include mix.sm {
    width: 90%;
    padding: 0;
  }
  @include mix.md {
    width: 80%;
  }
  @include mix.lg {
    width: 70%
  }
  @include mix.xl-min {
    width: 60%
  }
}