@use '../mixins' as mix;
@use '../variables' as var;

.headerImage {
    &__image-teaser-wrapper {
        height: calc(100vh - var.$header-height);
        min-height: 25rem;
        position: relative;
        isolation: isolate;
        
        @include mix.md {
            min-height: 40rem;
        }
    }

    &__image-wrapper {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;
    }

    &__image {
        height: 100%;
        width: 100%;
        object-fit: cover;
        animation: motionBackground 30s ease infinite alternate;
        position: absolute;
        z-index: -1;
    }

    @keyframes motionBackground {
        0% {
            transform-origin: bottom left;
            transform: scale(1);
        }
        100% {
            transform: scale(1.2);
            
        }
    }

    &__title-wrapper {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 30%;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;   
        
        @include mix.sm {
            top: 20%;
            left: 5%;
            transform: translateX(-5%);
            max-width: 65%;
        }
        
        @include mix.xl-med {
            text-align: left;
            flex-direction: row;
            top: 30%;
        }
    }

    &__image-title {
        height: 4rem;

        @include mix.sm {
            height: 15rem;
        }

        @include mix.lg {
            height: 16rem;
        }
    }

    &__main-title {
        font-family: var.$title-font;
        font-size: 2.5rem;
        font-weight: 600;
        
        @include mix.md {
            font-size: 5.5rem;
        }
        
        @include mix.xl-min {
            font-size: 6.5rem;
        }

        &--color-black {
            color: rgba(21, 20, 20, 0.959);
        }
        &--color-white {
            color: rgba(255, 252, 252, 0.894);
        }
    }
    
}