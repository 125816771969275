@use '../mixins' as mix;
@use '../variables' as var;

.contactForm {
    height: 35rem;

    @include mix.lg {
        height: calc(100vh - var.$header-height);
        min-height: 31.25rem;
    }

    &__wrapper {
        width: 100%;
        height: 100%;
        position: relative;
        isolation: isolate;
    }

    &__img-wrapper {
        height: 100%;
        width: 100%;
    }

    &__img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        z-index: 1;
    }
    
    &__form-container {
        max-height: 31.25rem;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 80%;
        transform: translate(-50%, -50%);
        z-index: 2;
        padding: 2rem 1rem;
        border: 1px solid rgba(86, 82, 82, 0.1);
        border-radius: var.$border-med;
        background-color: rgba(255, 255, 255, 0.95);
        box-shadow: 10px 10px 10px rgba(30,30,30,0.5);
        color: #fff;
        -webkit-backdrop-filter: blur(3px);
        backdrop-filter: blur(3px);
        background-color: rgba(255, 255, 255, 0.5);

        @include mix.md {
            width: 60%;    
        }

        @include mix.lg {
            padding: 4rem 3rem;
            width: 40rem;
        }
    }

    &__title {
        color: var.$clr-gray600;
        font-size: 1.5rem;
        font-weight: 900;
    }
    
    &__form {
        max-width: 25rem;
        margin: 0 auto;
        border-radius: var.$border-med;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    &__input, &__textarea, &__button {
        height: 50px;
        padding-left: 5px;
        font-size: 1.25rem;
        outline: none;
    }
    
    &__input, &__textarea {
        transition: all 350ms ease-in-out;
        border: 1px solid rgba(255, 255, 255, 0.5);

        @media (hover: hover) and (pointer: fine) {
            cursor: pointer;
            &:hover {
                border-color: var.$clr-primary-light;
            }
        }

        &:focus {
            border-color: var.$clr-primary-light;
        }
    }

    &__textarea {
        height: 8rem;
        padding-top: 5px;
    }
}