@use '../variables' as var;
@use '../mixins' as mix;

.titleText-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: center;

    &__title {
        font-size: 1.5rem;
        font-family: var.$title-font;
        font-weight: 900;
        overflow: hidden;
        hyphens: auto;
    }

    &__text {
        font-size: 1.15rem;
        font-family: var.$content-font;
    }

    @include mix.md {
        text-align: left;
    }
}