@use '../variables' as var;
@use '../mixins' as mix;

.blogpost {
    &__title {
        padding-bottom: 2rem;
        font-family: var.$title-font;
    }

    &__text-container {
        font-family: var.$content-font;
        width: 100%;
    }

    &__button-wrapper {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        width: 100%;
        max-width: fit-content;

        @include mix.sm {
            flex-direction: row-reverse;
        }
    }

    &__button {
        width: 100% !important;
    }
}