@use '../variables' as var;
@use '../mixins' as mix;

.header {
    $root-block: &;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1rem 0.5rem;
    height: var.$header-height;
    background: var.$red-blue-background-gradient;
    
    @include mix.sm {
        padding: 1rem 2rem;
    }

    &__logo-wrapper {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        gap: 0.5rem;
        
        @media (hover: hover) and (pointer: fine) {
            cursor: pointer;
        //    #{$root-block}__logo-text {
        //         transition: all 0.3s ease-in-out;
        //     }
        //     &:hover {
        //         #{$root-block}__logo-text {
        //             fill: #000;
        //         }
        //     }
        }
    }
    
    &__logo-icon {
        width: 2.75rem;
        height: 2.75rem;
        align-self: center;

        @include mix.sm {
            width: 3.25rem;
            height: 3.25rem;
        }
    }

    &__logo-text {
        width: 6rem;

        @include mix.sm {
            width: auto;
        }
    }
}