@use '../mixins' as mix;
@use '../variables' as var;

.home {
    &__section-container {
        padding: 2rem 0;
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

    &__section-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__section-wrapper {
        text-align: center;

        @include mix.md {
            text-align: left;
        }
    }

    &__section-title {
        padding-bottom: 1rem;
        font-size: 1.8rem;
    }

    &__section-image-crypto-eight {
        margin: 3rem 0;
    }
    
    &__section-trust-slider-title {
        text-align: center;
        margin-bottom: 2rem;
    }

    &__section-trust-slider {
        
        .swiper {
            width: 300px;
            height: 300px;
        }
        
        .swiper-slide {
            border-radius: 5px;
            background-position: center;
            background-size: cover;
        }
        
        .swiper-slide img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .swiper-pagination-bullet {
            background-color: var.$clr-primary;
            opacity: 0.8;
            width: 10px;
            height: 10px;
            outline: none;
        }

        .swiper-pagination-bullet-active {
            opacity: 1 !important;
            width: 14px;
            height: 14px;
        }
    }

    &__section-trust-slider-image-containter {
        position: relative;
        width: 100%;
        height: 100%;
        margin: 0;

        h3 {
            position: absolute;
            background: rgba(220, 220, 220, 0.38);
            padding: 0.2rem;
            border-radius: 5px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-family: 'Bumbon';
        }
    }

    &__button-container {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        @include mix.md {
            flex-direction: row;
            justify-content: space-around;
        }
    }
    
    &__button-wrapper {
        display: flex;
        justify-content: center;
    }

    &__button {
        align-self: center;
    }
}