@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bad+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Slab:wght@100;400&family=Padauk&family=Righteous&family=Vollkorn&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa&display=swap');

@font-face {
    font-family: 'Bumbon';
    src: local('Bumbon'), url(../fonts/LDBumbon-Regular.woff2) format('woff');
}
@font-face {
    font-family: 'Bumbon';
    src: local('Bumbon'), url(../fonts/LDBumbon-Italic.woff2) format('woff');
}
@font-face {
    font-family: 'Bumbon';
    font-weight: 900;
    src: local('Bumbon'), url(../fonts/LDBumbon-Bold.woff2) format('woff');
}
@font-face {
    font-family: 'Bumbon';
    font-weight: 900;
    src: local('Bumbon'), url(../fonts/LDBumbon-BoldItalic.woff2) format('woff');
}