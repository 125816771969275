@use '../mixins' as mix;
@use '../variables' as var;

.tabs {
    &__accordion-content-container {
        display: block;
        width: 100%;
    
        @include mix.md {
            display: none;
        }
    }
    
    &__accordion-content-wrapper {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
    }
    
    &__accordion-content-title {
        text-align: center;
    }
    
    &__tab-content-wrapper {
        display: none;
        
        @include mix.md {
            display: block;
        }
    }
    
    .react-tabs__tab--selected {
        background: var.$red-blue-background-gradient;
        color: var.$clr-gray100;
    }
    
    &__tabPanel-content {
        padding: 1.5rem 0.5rem;
    
        @include mix.md {
            border-left: 1px solid #aaa;
            border-bottom: 1px solid #aaa;
            border-right: 1px solid #aaa;
            box-shadow: 10px 20px 15px silver;
            padding: 2rem;
        }
    
        h4 {
            font-family: 'Bumbon';
            font-weight: 900;
        }
    }
    
    .react-tabs {
        ul {
            margin: 0;
        }
    }
    
    .accordion-button {
        &:focus {
            outline: none !important;
            outline-offset: none !important;
            box-shadow: none !important;
        }
    }
    
    .accordion-button:not(.collapsed) {
        color: var.$clr-gray100;
        background: var.$red-blue-background-gradient;
        
        &::after {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
        }
    }
}