@use '../variables' as var;
@use '../mixins' as mix;

.footer {
    background: var.$red-blue-background-gradient;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    
    &__wrapper {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

    &__list-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
        
        @include mix.lg {
            flex-direction: row;
            justify-content: space-evenly;
        }
    }
    &__list-items {
        list-style-type: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1rem;

        @include mix.lg {
            align-self: start;
            
            &--contact {
                max-width: 10rem;
            }
        }
    }

    &__list-item {
        text-align: center;

        @include mix.lg {
            text-align: left;
        }

        &--dev {
            text-align:center;
        }
    }

    &__list-item-link {
        text-decoration: none;
        color: #000; 
        transition: all 250ms ease-in-out;

        &:hover {
            color: #000 !important;
        }

        @media (hover: hover) and (pointer: fine) {
            &:hover {
                text-decoration: underline;
                color: #000 !important;
                cursor: pointer;
            }
        }

        &--social-media {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;

            @include mix.lg {
                justify-content: start;
            }
        }
        
    }

    &__title {
        text-align: center;
        color: #000;
        @include mix.lg {
            text-align: left;
        }
    }
}