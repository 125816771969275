@use '../variables' as var;
@use '../mixins' as mix;

.blogposts {
    $root-block: &;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
    grid-gap: 1rem;
    width: 100%;

    &__card {
        display: flex !important;
        height: 100%;
        @media (hover: hover) and (pointer: fine) {
            &:hover {
                #{$root-block}__card-image {
                    transform: scale(1.05);
                }
            }
        }
    }

    &__link-card-container {
        text-decoration: none;
        color: var.$clr-bg;

        &:hover {
            color: var.$clr-primary;
        }
    }

    &__card-body {
        display: flex;
        flex-direction: column;
    }

    &__card-image-container {
        height: 33%;
        overflow: hidden;
        border-radius: 0.375rem;
        margin: 0;
    }

    &__card-image {
        transition: all 500ms ease-in-out;
        object-fit: cover;
        height: 100%;
    }

    &__card-button-container {
        margin-top: auto;
    }

    &__card-button {
        padding: 0.5rem !important;
    }
}