// breakpoints
// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;

// Desktops
$screen-xl-med: 1680px;

// Desktop
$screen-xxl: 1920px;


// Small devices
@mixin sm {
    @media (min-width: #{$screen-sm-min}) {
        @content;
    }
 }
 
 // Medium devices
 @mixin md {
    @media (min-width: #{$screen-md-min}) {
        @content;
    }
 }
 
 // Large devices
 @mixin lg {
    @media (min-width: #{$screen-lg-min}) {
        @content;
    }
 }
 
 // Extra large devices
 @mixin xl-min {
    @media (min-width: #{$screen-xl-min}) {
        @content;
    }
 }
 // Extra large devices
 @mixin xl-med {
    @media (min-width: #{$screen-xl-med}) {
        @content;
    }
 }
 // Extra large devices
 @mixin xxl {
    @media (min-width: #{$screen-xxl}) {
        @content;
    }
 }

 // Custom devices
@mixin rwd($screen) {
    @media (min-width: $screen+ 'px') {
        @content;
    }
 }