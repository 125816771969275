@use '../mixins' as mix;
@use '../variables' as var;

.offer {
    &__text-content-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2rem 0;
        gap: 3rem;
    }

    &__short-summary-wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
        grid-gap: 2rem;
        width: 100%;

        @include mix.md {
            grid-gap: 0rem;
        }
    }

    &__short-summary-list-wrapper {        
        list-style-type: disc;
        list-style-position: inside;
        font-family: var.$content-font;

        @include mix.md {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        @include mix.lg {
            white-space: nowrap;
        }
    }

    &__short-summary-list-item-title {
        font-family: var.$title-font;
        font-size: 2.25rem;
        padding-bottom: 1rem;
        font-weight: 600;
    }

    &__short-summary-list-item {
        font-size: 1.5rem;
    }

    &__short-summary-image-wrapper {
        display: flex;
        justify-content: center;
        height: 20rem;
    }

    &__short-summary-image {
        height: 100%;
        object-fit:contain;
        border-radius: 50px 2px 50px 2px;
        overflow: hidden;
        position: relative;
        box-shadow: 10px 20px 15px silver;        
    }

    &__section-image-description-container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
        grid-gap: 2rem;
        width: 100%;
    }

    &__section-image-wrapper {
        position: relative;
        isolation: isolate;
        display: flex;
        justify-content: center;
        width: 100%;
        height: 24rem;
    }

    &__image-wrapper {
        border-radius: 50px 2px 50px 2px;
        overflow: hidden;
        position: relative;
        box-shadow: 10px 20px 15px silver;
        width: 100%;
        height: 100%;
    }

    &__image {
        height: 100%;
        width: 100%;
        object-fit: cover;
        animation: motionBackgroundMobile 30s ease infinite alternate;
        position: absolute;
        z-index: -1;

        @include mix.md {
            animation: motionBackground 30s ease infinite alternate;
        }
    }

    @keyframes motionBackgroundMobile {
        0% {
            transform-origin: center right;
            transform: scale(1);
        }
        100% {
            transform: scale(1.2);
            
        }
    }

    @keyframes motionBackground {
        0% {
            transform-origin: bottom right;
            transform: scale(1);
        }
        100% {
            transform: scale(1.2);
            
        }
    }

    &__list-item-span {
        font-weight: 800;
    }

    &__list-item-appendum {
        font-style: italic;
    }
}