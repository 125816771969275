@import './fonts';
@import 'animate.css';
@import '../../node_modules/react-tabs/style/react-tabs.scss';
@import "~bootstrap/scss/bootstrap";

@import './components/components';
@import './pages/pages';
@import './mixins';
@import './variables';
@import './fragments/fragments';
@import '../index';
